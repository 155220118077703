<template>
  <div class="toolbox-zoom">
    <img class="toolbox-zoom-button" src="../../../../assets/fileToolbox/zoom-out.png" @click="() => zoomAction(false)" />
    <div class="spacer" />
    <span class="toolbox-zoom-text">{{zoom + '%'}}</span>
    <div class="spacer" />
    <img class="toolbox-zoom-button" src="../../../../assets/fileToolbox/zoom-in.png" @click="() => zoomAction(true)" />
  </div>
</template>

<script>
export default {
  name: "zoom",
  emits: [
      "zoom-action"
  ],
  props: {
    zoom: {
      type: Number,
      default: 80
    }
  },
  setup(props, { emit }) {
    const zoomAction = isZoomIn => {
      if(isZoomIn && props.zoom < 200) {
        emit("zoom-action", props.zoom + 10)
      } else if(!isZoomIn && props.zoom > 30) {
        emit("zoom-action", props.zoom - 10)
      }
    }
    return {
      zoomAction
    }
  }
}
</script>

<style scoped>
.toolbox-zoom {
  height: 14px;
  margin: 10px 30px;
  display: flex;
  align-items: center;
}
.spacer {
  width: 30px;
}
.toolbox-zoom-text {
  font-size: 14px;
  line-height: 15px;
  text-align: center;
  color: #FFFFFF;
}
.toolbox-zoom-button {
  height: 100%;
  cursor: pointer;
}
</style>