<template>
  <div class="document-grid-container" :style="{display: open ? 'flex' : 'none'}">
<!--    <img v-for="item in documents" class="document-grid-image" v-bind:key="item.id" :src="item.thumbnail" @click="$emit('grid-document-click', item.id)" />-->
    <grid-image-overlay v-for="item in documents" v-bind:key="item.id" :image="item.thumbnail" :name="item.name" :page-count="item.pageCount" @click="$emit('grid-document-click', item.id)" />
  </div>
</template>

<script>
import GridImageOverlay from "@/components/common/FileViewer/GridImageOverlay";

export default {
  name: "GridView",
  emits: [
      "grid-document-click"
  ],
  components: {
    "grid-image-overlay": GridImageOverlay
  },
  props: {
    open: {
      type: Boolean,
      default: false
    },
    documents: {
      type: Object,
      default: () => {}
    }
  }
}
</script>

<style scoped>
.document-grid-container {
  width: 100vw;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  position: absolute;
  top:68px;
  left: 0;
  z-index: 2;
  background-color: #FFFFFF;
  height: calc(100vh - 68px);
  padding: 10px 50px;
  overflow-y: scroll;
  box-sizing: border-box;
}

.document-grid-image {
  width: calc(16.66% - 20px);
  box-sizing: border-box;
  margin: 10px;
}

@media screen and (max-width: 992px) {
  .document-grid-container {
    height: auto;
    top: 0;
    width: 100vw;
    padding: 7.5px;
  }
}
</style>