<template>
  <div class="detail-container">
    <div class="images-container" ref="detailContainerRef">
      <img
          v-for="(img, index) in images"
          v-bind:key="index"
          class="detail-image"
          :class="viewType === 'fit' ? (activeItem === index ? 'fit-active' : 'fit-disabled' ) : ''"
          :index="index"
          :src="img"
          :style="viewType === 'fit' ? {height: zoom + '%'} : { width: zoom + '%'}"
      />
    </div>
    <toolbox
        v-if="!isMobile"
        class="detail-toolbox"
        :pages="images.length"
        :active-page="activeItem"
        @navigator-action="value => $emit('navigator-action', value)"
        :zoom="zoom"
        @zoom-action="changeZoom"
        :view-type="viewType"
        @views-action="changeViewType"
    />
  </div>
</template>

<script>
import { ref, onMounted, watch, inject } from "vue";

import listenerHelper from "@/utils/helpers/listenerHelper";
import toolbox from "@/components/common/FileViewer/toolbox";
export default {
  name: "Detail",
  emits: [
      "change-page",
      "navigator-action",
      "grid-view-action"
  ],
  components: {
    "toolbox": toolbox
  },
  props: {
    images: {
      type: Array,
      default: () => []
    },
    activeItem: {
      type: Number,
      default: 0
    }
  },
  setup(props, { emit }) {
    const global = inject("global");
    const detailContainerRef = ref(null);
    const scrollListener = () => {
      const viewCenter = detailContainerRef.value.scrollTop + detailContainerRef.value.clientHeight / 2;
      const currentItem = parseInt(Array.from(detailContainerRef.value.children).reverse().find(item => {
        return item.offsetTop < viewCenter
      }).getAttribute("index"));
      if(currentItem !== props.activeItem) {
        emit("change-page", currentItem)
      }
    }
    onMounted(() => {
      detailContainerRef.value.addEventListener("scroll", scrollListener, listenerHelper.passiveIfSupported())
      detailContainerRef.value.focus()
    })



    const viewType = ref("continuous");
    const changeViewType = type => {
      if(type === "fullscreen") {
        detailContainerRef.value.classList.remove("fit")
        detailContainerRef.value.classList.add("continuous")
        viewType.value = "continuous"
        zoom.value = 80
        if (detailContainerRef.value.requestFullscreen) {
          detailContainerRef.value.requestFullscreen();
        } else if (detailContainerRef.value.webkitRequestFullscreen) { /* Safari */
          detailContainerRef.value.webkitRequestFullscreen();
        } else if (detailContainerRef.value.msRequestFullscreen) { /* IE11 */
          detailContainerRef.value.msRequestFullscreen();
        }
      } else if(type==="grid") {
        emit("grid-view-action")
      } else {
        detailContainerRef.value.classList.remove(viewType.value)
        detailContainerRef.value.classList.add(type)
        viewType.value = type
        if(type === 'fit') {
          detailContainerRef.value.removeEventListener("scroll", scrollListener, listenerHelper.passiveIfSupported())
        } else {
          detailContainerRef.value.addEventListener("scroll", scrollListener, listenerHelper.passiveIfSupported())
        }
      }
    }

    const zoom = ref(80);
    const changeZoom = value => {
      const prevZoom = zoom.value;
      zoom.value = value
      if(viewType.value === 'continuous') {
        detailContainerRef.value.scrollTop = detailContainerRef.value.scrollTop * value / prevZoom
      }
    }
    watch(() => props.images, () => {
      detailContainerRef.value.scrollTop = 0
      zoom.value = 80
    }, { deep: true })

    return {
      detailContainerRef,
      zoom,
      changeZoom,
      viewType,
      changeViewType,
      isMobile: global.state.isMobile
    }
  }
}
</script>

<style scoped>
.detail-container {
  background-color: #F2F2F3;
  width: calc(100% - 225px);
  height: 100%;
  position: relative;
}
.images-container {
  height: 100%;
  width: 100%;
  overflow: scroll;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.images-container.fit {
  display: block;
}

.detail-image {
  margin: 20px 0;
}

.detail-image.fit-active {
  margin: 0;
}

.detail-image.fit-disabled {
  display:none;
}

@media screen and (max-width: 992px) {
  .detail-toolbox {
    display: none;
  }
  .detail-container {
    width: 100%;
  }
  .detail-image {
    width: 95%!important;
  }
}

</style>