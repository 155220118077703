<template>
  <div class="toolbox-navigator">
    <img class="toolbox-navigator-button" src="../../../../assets/fileToolbox/navigator-left.png" @click="() => navigatePage(false)" />
    <div class="spacer" />
    <span class="toolbox-navigator-text">{{$t("TOOLBOX_NAVIGATOR_TEXT", {currentPage: activePage + 1, totalPages: pages})}}</span>
    <div class="spacer" />
    <img class="toolbox-navigator-button" src="../../../../assets/fileToolbox/navigator-right.png" @click="() => navigatePage(true)" />

  </div>
</template>

<script>
export default {
  name: "navigator",
  emits: [
      "navigator-action"
  ],
  props: {
    pages: {
      type: Number,
      default: 0
    },
    activePage: {
      type: Number,
      default: 0
    }
  },
  setup(props, { emit }) {
    const navigatePage = isForward => {
      if(!isForward && props.activePage > 0) {
        emit('navigator-action', props.activePage - 1)
      } else if(isForward && props.activePage < props.pages - 1) {
        emit('navigator-action', props.activePage + 1)
      }
    }
    return {
      navigatePage
    }
  }
}
</script>

<style scoped>
.toolbox-navigator {
  height: 14px;
  margin: 10px 30px;
  display: flex;
  align-items: center;
}
.spacer {
  width: 30px;
}
.toolbox-navigator-text {
  font-size: 14px;
  line-height: 15px;
  text-align: center;
  color: #FFFFFF;
}
.toolbox-navigator-button {
  height: 100%;
  cursor: pointer;
}
</style>