<template>
  <div class="toolbox-views">
    <img class="toolbox-views-button" :src="require(`../../../../assets/fileToolbox/view-continuous${viewType === 'continuous' ? '-active' : ''}.png`)" @click="() => changeView('continuous')" />
    <div class="spacer" />
    <img class="toolbox-views-button" :src="require(`../../../../assets/fileToolbox/view-fit${viewType === 'fit' ? '-active' : ''}.png`)" @click="() => changeView('fit')" />
    <div class="spacer" />
    <img class="toolbox-views-button" src="../../../../assets/fileToolbox/view-fullscreen.png" @click="() => changeView('fullscreen')" />
  </div>
</template>

<script>
export default {
  name: "views",
  emits: [
    "views-action"
  ],
  props: {
    viewType: {
      type: String,
      default: "continuous"
    }
  },
  setup(props, { emit }) {
    const changeView = type => {
      if(props.viewType !== type) {
        emit('views-action', type)
      }
    }
    return {
      changeView
    }
  }
}
</script>

<style scoped>
.toolbox-views {
  height: 14px;
  margin: 10px 30px;
  display: flex;
  align-items: center;
}
.spacer {
  width: 30px;
}
.toolbox-views-button {
  height: 100%;
  cursor: pointer;
}
</style>