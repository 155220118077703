<template>
  <div class="overview-container">
    <span>{{ name }}</span>
    <image-overlay v-for="(image, index) in images" v-bind:key="index" :index="index" :active="activeItem === index" @overview-click="(arg) => $emit('overview-click', arg)">
      <img class="overview-image" :src="'http://doc.cdwscan.com/' + image.split('/').slice(-1)[0] + '?w=115'" />
    </image-overlay>
  </div>
</template>

<script>
import ImageOverlay from "@/components/common/FileViewer/ImageOverlay";
export default {
  name: "Overview",
  emits: [
      "overview-click"
  ],
  components: {
      "image-overlay": ImageOverlay
  },
  props: {
    images: {
      type: Array,
      default: () => []
    },
    name: {
      type: String,
      default: ""
    },
    activeItem: {
      type: Number,
      default: 0
    }
  }
}
</script>

<style scoped>
.overview-container {
  background-color: #FFFFFF;
  width: 225px;
  height: 100%;
  overflow: scroll;
  display: inline-block;
}
.overview-container span {
  display: block;
  width: 115px;
  margin: 40px auto;
  font-weight: 600;
  font-size: 14px;
  line-height: 15px;
  text-align: start;

  color: #2E293D;
}
.overview-image {
  width: 100%;
  border-radius: 16.4px;
}
</style>