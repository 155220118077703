<template>
  <file-viewer :document="activeDocument ? documents[activeDocument] : {}" :is-subscribed="isSubscribed" :style="gridViewOpen ? {display: 'none'} : {}" />
  <grid-view :open="gridViewOpen" :documents="Object.keys(documents).map(item => { return { thumbnail: documents[item].images[0], id: item, name: documents[item].name, pageCount: documents[item].images.length} })" @grid-document-click="setActiveDocument" />
  <mobile-toolbox v-if="global.state.isMobile" />
</template>

<script>
import { ref, onMounted, watch, inject, computed } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useI18n } from "vue-i18n";

import JSZip from "jszip";

import MobileToolbox from "@/components/common/FileViewer/MobileToolbox";
import GridView from "@/components/common/FileViewer/GridView";
import FileViewer from "@/components/common/FileViewer";
import documentHelper from "@/utils/helpers/documentHelper";
export default {
  name: "DocumentPage",
  components: {
    "file-viewer": FileViewer,
    "grid-view": GridView,
    "mobile-toolbox": MobileToolbox
  },
  setup() {
    const global = inject("global");
    const { t } = useI18n({ useScope: 'global' })

    const documents = ref({});
    const isSubscribed = ref(false);
    const activeDocument = ref(null);
    const setActiveDocument = document =>  {
      gridViewOpen.value = false
      global.setIsDocumentDetail(true)
      activeDocument.value = document
    }

    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    const downloadSingleFile = computed(() => global.state.isMobile && !global.state.isSafari && /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream )

    watch(() => global.state.isDocumentDetail, value => gridViewOpen.value = !value)

    const route = useRoute();
    const router = useRouter();
    const getDocuments = () => {
      const reqId = route.params.documentId;
      if(reqId) {
        fetch(`https://doc.cdwscan.com/shared/files/${reqId}`).then(res => res.json()).then(res => {
          if(res.status.code === 410)
            return router.push("/expired-document")
          if(res.status.code !== 200)
            return router.push("/")
          documents.value = res.payload.documents
          isSubscribed.value = res.payload.isSubscribed
          if(Object.keys(res.payload.documents).length > 0) {
            activeDocument.value = Object.keys(res.payload.documents)[0]
          }
          if(Object.keys(res.payload.documents).length === 1) {
            gridViewOpen.value = false
          }
        }).catch(err => {
          console.log(err)
          // if(err.status.code === 410)
          //   router.push("/expired-document")
          // router.push("/")
        })
      }

    }
    watch(() => route.params.documentId, getDocuments)

    const gridViewOpen = ref(true);

    watch(() => global.state.downloadTriggered, value => {
      if(value) {
        const promises = []
        const zip = new JSZip();
        const exportOptions = {
          isSubscribed: isSubscribed.value,
          watermarkText: t("DOCUMENT_WATERMARK_TEXT")
        }
        if (downloadSingleFile.value) {
          window.open("https://doc.cdwscan.com/shared/export-pdf/" + activeDocument.value, "_blank");
          global.setDownloadTriggered(false);
        } else if(!gridViewOpen.value) {
          if(value === "PDF") {
            exportOptions.cb = data => {
              data.save(documents.value[activeDocument.value].name + ".pdf")
              global.setDownloadTriggered(false)
            }
            documentHelper.exportAsPDF(documents.value[activeDocument.value].images, exportOptions)
          } else {
            exportOptions.cb = data => {
              zip.folder(activeDocument.value);
              if(!data) {
                return;
              }
              data.forEach((img, index) => {
                if(img.status === "fulfilled")
                  zip.file('page'+ (index + 1) +'.jpg', img.value)
              })
              documentHelper.downloadZip(zip, activeDocument.value + "zip", () => global.setDownloadTriggered(false))
            }
            documentHelper.exportAsJPG(documents.value[activeDocument.value].images, exportOptions)
          }
        } else {
          Object.keys(documents.value).forEach(key => {
            promises.push(new Promise(resolve => {
              const currentDocument = documents.value[key]
              const currentExportOptions = { ...exportOptions }
              currentExportOptions.cb = data => {
                if(!data) {
                  resolve()
                }
                if(Array.isArray(data)) {
                  zip.folder(currentDocument.name)
                  data.forEach((img, index) => {
                    zip.file(currentDocument.name + '/page'+ (index + 1) +'.jpg', img.value)
                  })
                } else {
                  zip.file(currentDocument.name + ".pdf", data.output("dataurlstring").split(",")[1], { base64: true });
                }
                resolve()
              }
              if(value === "PDF") {
                setTimeout( () => {
                  documentHelper.exportAsPDF(currentDocument.images, currentExportOptions)
                },100)

              } else if(value === "JPG") {
                documentHelper.exportAsJPG(currentDocument.images, currentExportOptions)
              }
            }))
          })
          Promise.allSettled(promises).then(() =>  {
            console.log("here")
            documentHelper.downloadZip(zip, "scanner_files.zip", () => global.setDownloadTriggered(false))
          })
        }

      }

    })


    onMounted(() => {
      getDocuments()
    })

    return {
      documents,
      activeDocument,
      gridViewOpen,
      isSubscribed,
      setActiveDocument,
      global
    }
  }
}
</script>

<style scoped>

</style>