export default {
    exportAsPDF(images, exportOptions) {
        const { watermarkText, isSubscribed, cb } = exportOptions;
        const { jsPDF } = require("jspdf");
        const doc = new jsPDF('p', 'px', 'a4');
        const pageWidth = doc.internal.pageSize.getWidth();
        const pageHeight = doc.internal.pageSize.getHeight();
        const promises = [];
        images.forEach(path => {
            promises.push(fetch(path).then(response => response.blob())
                .then(blob => {
                    const img = new Image();
                    img.src = window.URL.createObjectURL(blob);
                    return new Promise(resolve => {
                        img.onload = function () {

                            const widthRatio = pageWidth / img.width;
                            const heightRatio = pageHeight / img.height;
                            const ratio = widthRatio > heightRatio ? heightRatio : widthRatio;

                            const canvasWidth = img.width * ratio;
                            const canvasHeight = img.height * ratio;

                            const marginX = (pageWidth - canvasWidth) / 2;
                            const marginY = (pageHeight - canvasHeight) / 2;
                            doc.addPage();
                            doc.addImage(img, "PNG", marginX, marginY, canvasWidth, canvasHeight);
                            doc.setFontSize(14);
                            if (!isSubscribed) {
                                doc.setFillColor(98, 125, 255);
                                doc.rect((pageWidth - canvasWidth) / 2, pageHeight - 31 - ((pageHeight - canvasHeight) / 2), 160, 15, 'F');

                                doc.setTextColor(255, 255, 255);
                                doc.text(watermarkText, 10 + (pageWidth - canvasWidth) / 2, pageHeight - 20 - ((pageHeight - canvasHeight) / 2));
                            }
                            resolve();
                        }
                    })
                }).catch(err => {
                    console.log(err)
                }))
        })
        promises.length > 0 ? Promise.allSettled(promises).then(() => {
            doc.deletePage(1)
            // doc.save(filename + ".pdf")
            cb(doc)
            // cb(doc.output("dataurlstring").split(",")[1], filename + ".pdf");
        }) : setTimeout(cb, 100);

    },
    exportAsJPG(images, exportOptions) {
        const { watermarkText, isSubscribed, cb } = exportOptions;
        const promises = [];
        images.forEach((img) => {
            promises.push(fetch(img).then(response => response.blob())
                .then(blob => {
                    return new Promise(resolve => {
                        const c = document.createElement("canvas"),  // create a temp. canvas
                            ctx = c.getContext("2d");
                        const img = new Image();
                        img.onload = function(){
                            c.width = img.width;
                            c.height = img.height;
                            ctx.drawImage(img, 0, 0)
                            if(!isSubscribed) {
                                ctx.fillStyle = '#6781FF';
                                ctx.fillRect(0,img.height * 0.9625, img.height * 0.32, img.height * 0.020)
                                ctx.fillStyle = '#FFFFFF';

                                ctx.font = img.height * 0.017 + "px sans-serif";
                                ctx.fillText(watermarkText, 10,img.height * 0.9785)
                            }
                            c.toBlob(function(blob) {
                                resolve(blob);
                            }, "image/jpeg", 0.75);
                        }
                        img.src = window.URL.createObjectURL(blob);
                    })


                }).catch(err => {
                    console.log(err)
                }))
        })
        promises.length > 0 ? Promise.allSettled(promises).then(res => {
            cb(res)
        }) : setTimeout(cb, 100);
    },
    downloadDataURL(dataURL, filename, cb) {
        const a = document.createElement("a");
        a.href = dataURL
        a.download = filename
        document.body.appendChild(a)

        a.click()
        a.remove()
        cb()
    },
    downloadZip(zipFile, filename, cb) {
        console.log("zipfile download")
        zipFile.generateAsync({type:"base64", streamFiles: false}).then( base64 => {
            this.downloadDataURL("data:application/zip;base64," + base64, filename, cb)
        })
    }
}