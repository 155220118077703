<template>
  <div class="overview-overlay-container" :class="active ? 'active': ''" @click="() => $emit('overview-click', index)">
    <slot />
    <div class="page-indicator">
      <img class="page-indicator-image" src="../../../assets/file-indicator.png" />
      <span>{{ index + 1 }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "ImageOverlay",
  emits: [
      "overview-click"
  ],
  props: {
    index: {
      type: Number,
      default: 0
    },
    active: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped>
.overview-overlay-container {
  position: relative;
  width: 115px;
  background: rgba(246, 247, 250, 0.7);
  border-radius: 19.7px;
  padding: 10px;
  box-sizing: border-box;
  margin: 20px auto;
  cursor: pointer;
}
.overview-overlay-container.active {
  box-shadow:0 0 0 3.28445px #6781FF inset;
  overflow: hidden;

}
.page-indicator {
  position: absolute;
  right: 0;
  bottom: 0;
  height: 52px;
  width: 52px;
}
.page-indicator-image {
  width: 100%;
  height: 100%;
}
.page-indicator span {
  position: absolute;
  right: 17px;
  bottom: 16px;
  transform: translate(50%, 50%);
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 20px;
  /* identical to box height, or 156% */

  text-align: center;

  color: #6781FF;

}
</style>