<template>
  <div class="document-toolbox">
    <navigator :pages="pages" :active-page="activePage" @navigator-action="value => $emit('navigator-action', value)" />
    <zoom :zoom="zoom" @zoom-action="value => $emit('zoom-action', value)" />
    <views :view-type="viewType" @views-action="value => $emit('views-action', value)" />
  </div>
</template>

<script>
import navigator from "@/components/common/FileViewer/toolbox/navigator";
import zoom from "@/components/common/FileViewer/toolbox/zoom";
import views from "@/components/common/FileViewer/toolbox/views";
export default {
  name: "index",
  emits: [
      "navigator-action",
      "zoom-action",
      "views-action"
  ],
  components: {
    "navigator": navigator,
    "zoom": zoom,
    "views": views
  },
  props: {
    pages: {
      type: Number,
      default: 0
    },
    activePage: {
      type: Number,
      default: 0
    },
    zoom: {
      type: Number,
      default: 80
    },
    viewType: {
      type: String,
      default: "fit"
    }
  }
}
</script>

<style scoped>
.document-toolbox {
  position: absolute;
  bottom: 35px;
  left: 50%;
  transform: translateX(-50%);
  padding: 20px 30px;
  max-width: 100%;
  display: flex;
  align-items: center;
  background: rgba(46, 41, 61, 0.9);
  border-radius: 22px;
}
</style>