<template>
  <div class="grid-image-overlay-container">
    <template v-if="pageCount > 1">
      <img class="multipage-corner" src="../../../assets/grid-overlay-corner.png" />
      <div class="page-counter">
        <span>{{pageCount}}</span>
      </div>
    </template>
    <div class="grid-image-overlay-image-container">
      <img class="grid-image-overlay-image" :src="'http://doc.cdwscan.com/' + image.split('/').slice(-1)[0] + '?w=220&h=204'" />
    </div>
    <div class="grid-image-overlay-info-container">
      <span> {{ name }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "GridImageOverlay",
  props: {
    image: {
      type: String,
      default: ""
    },
    name: {
      type: String,
      default: ""
    },
    pageCount: {
      type: Number,
      default: 1
    }
  }
}
</script>

<style scoped>
.grid-image-overlay-container {
  width: 250px;
  height: 330px;
  box-sizing: border-box;
  margin: 10px;
  background: #F6F7FA;
  border-radius: 20px;
  cursor: pointer;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.grid-image-overlay-image-container {
  width: 88%;
  height: 62%;
  margin: 4.2% auto 0;
  border-radius: 16px;
  overflow: hidden;
}

.grid-image-overlay-image {
  width: 100%;
}

.grid-image-overlay-info-container {
  width: 88%;
  margin: 8% auto;
  text-align: start;
  flex-grow: 1;
  display: flex;
  align-items: center;
}

.grid-image-overlay-info-container span {
  margin-left: 0;
  font-weight: 500;
  font-size: 17px;
  line-height: 184%;

  color: #2E293D;
}

.multipage-corner {
  position: absolute;
  top: 0;
  right: 0;
  height: 28%;
}
.page-counter {
  position: absolute;
  top: 7%;
  right: 7%;
  width: 31.5px;
  height: 31.5px;
  background: #6781FF;
  border-radius: 12.5px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.page-counter span {
  font-weight: bold;
  font-size: 13px;
  line-height: 25px;

  text-align: center;

  color: #FFFFFF;
}
@media screen and (max-width: 992px) {
  .grid-image-overlay-container {
    width: calc(50vw - 27.5px);
    height: calc(calc(50vw - 27.5px) * 33 / 25);
    margin: 7.5px;
  }
  .page-counter {
    width: calc(calc(50vw - 27.5px) * 3.15 / 25);
    height: calc(calc(50vw - 27.5px) * 3.15 / 25);
    border-radius: calc(calc(50vw - 27.5px) * 1.25 / 25);
  }
}
</style>