<template>
  <div id="toolbox-placeholder" />
  <div id="mobile-document-toolbox" class="mobile-toolbox-container" :style="isGridView && downloadSingleFile ? { display: 'none'} : {}">
    <div class="mobile-toolbox-button button-download" @click="triggerDownload">
      <span>{{$t(global.state.downloadTriggered ? "DOWNLOADING" : (downloadSingleFile ? "DOWNLOAD_AS_PDF" : "DOWNLOAD"))}}</span>
    </div>
    <div class="mobile-toolbox-button button-app">
      <span>{{$t("VIEW_IN_THE_APP")}}</span>
    </div>
  </div>
  <generic-modal :open="selectionModalOpen" :on-close="toggleSelectionModal" background="rgba(0,0,0,0)" size-mode-auto>
    <div class="selection-modal-container">
      <div class="selection-modal-item" @click="() => initiateDownload('PDF')">{{$t("DOWNLOAD_PDF_FILE")}}</div>
      <hr style="margin: 0; border: 0.5px solid rgba(0, 0, 0, 0.1);"/>
      <div class="selection-modal-item" @click="() => initiateDownload('JPG')">{{$t("DOWNLOAD_JPG_FILE")}}</div>
    </div>
  </generic-modal>
</template>

<script>
import { inject, computed, ref, watch } from "vue";

import GenericModal from "@/components/generic/GenericModal";
export default {
  name: "MobileToolbox",
  components: {
    "generic-modal": GenericModal
  },
  setup() {
    const global = inject("global")

    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    const downloadSingleFile = computed(() => global.state.isMobile && !global.state.isSafari && /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream )

    const triggerDownload = () => {
      if(downloadSingleFile.value) {
        global.setDownloadTriggered(true)
      } else {
        toggleSelectionModal()
      }
    }

    const isGridView = ref(false);
    watch(() => global.state.isDocumentDetail, value => isGridView.value = !value, { immediate: true })

    const selectionModalOpen = ref(false);
    const toggleSelectionModal = () => selectionModalOpen.value = !selectionModalOpen.value

    const initiateDownload = type => {
      global.setDownloadTriggered(type)
      toggleSelectionModal()
    }

    return {
      global,
      downloadSingleFile,
      triggerDownload,
      selectionModalOpen,
      toggleSelectionModal,
      initiateDownload,
      isGridView
    }
  }
}
</script>

<style scoped>
  .mobile-toolbox-container {
    position: fixed;
    z-index: 9999999999;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 20px 15px;
    box-sizing: border-box;
    background: #FFFFFF;
    box-shadow: 0 -1px 4px rgba(0, 0, 0, 0.05);
    display: flex;
    justify-content: center;
  }
  .mobile-toolbox-button {
    width: calc(50vw - 25px);
    padding: 20px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 5px;
    box-sizing: border-box;
    border-radius: 16px;
    cursor: pointer;
  }
  .button-app {
    background: #627DFF;
  }
  .button-app span {
    display: block;
    color: #FFFFFF;
    font-size: 3vw;
    line-height: 133%;
    font-weight: 600;

    text-align: center;
    text-transform: uppercase;
  }

  .button-download {
    border: 1px solid rgba(17, 13, 12, 0.2);
  }
  .button-download span {
    font-size: 3vw;
    line-height: 133%;
    font-weight: 600;

    text-align: center;
    text-transform: uppercase;
    color: #2E293D;
    opacity: 0.6;
  }
  #toolbox-placeholder {
    height: 96px;
  }
  .selection-modal-container {
    margin: 0 44px;
    width: calc(100vw - 88px);
    background: #FFFFFF;
    box-shadow: 0 8px 60px rgba(0, 0, 0, 0.15);
    border-radius: 16px;
  }
  .selection-modal-item {
    display: block;
    margin: 15px auto;
    font-weight: 600;
    font-size: 15px;
    line-height: 20px;

    color: #2C293D;
  }
</style>