<template>
  <div class="page-container">
    <overview v-if="!isMobile" :images="document.images" :active-item="activeItem" @overview-click="overviewClick" :name="document.name" />
    <detail ref="detailRef" :images="document.images" :active-item="activeItem" @change-page="scrollChanger" @navigator-action="overviewClick" />
  </div>
</template>

<script>
import { ref, watch, inject } from "vue";

import Overview from "@/components/common/FileViewer/Overview";
import Detail from "@/components/common/FileViewer/Detail";

export default {
  name: "index",
  components: {
    "overview": Overview,
    "detail": Detail
  },
  props: {
    document: {
      type: Object,
      default: () => {}
    },
    isSubscribed: {
      type: Boolean,
      default: false
    }
  },
  setup() {

    const detailRef = ref(null);
    const global = inject("global");


    const activeItem = ref(0);
    const scrollChanger = index => activeItem.value = index;

    const overviewClick = index => {
      activeItem.value = index
      detailRef.value.detailContainerRef.scrollTop = detailRef.value.detailContainerRef.children[index].offsetTop - 20;
    }

    watch(() => global.state.isDocumentDetail, value => {
      if(!value) {
        activeItem.value = 0
      }
    })

    return {
      activeItem,
      overviewClick,
      scrollChanger,
      detailRef,
      isMobile: global.state.isMobile
    }
  }
}
</script>

<style scoped>
.page-container {
  width: 100vw;
  display: flex;
  height: calc(100vh - 68px);
  position: relative;
  background-color: #F2F2F3;
}
@media screen and (max-width: 992px) {
  .page-container {
    height: auto;
  }
}
</style>